.measurement-sensor-table {
  background-color: transparent;
  width: 100%;
}

.measurement-sensor-table > table {
  background-color: transparent;
}

.measurement-sensor-table-row {
  margin-left: 5px;
  padding-bottom: 5px;

  white-space: nowrap;
}

.measurement-sensor-table-row[data-linking-ended="true"] {
  background-color: inherit;
  border-bottom: 1px solid darkgray !important;
}

.measurement-sensor-table-row > th,
td {
  margin-left: 5px;
  vertical-align: top !important;
  padding: 2px 5px 0px 5px !important;

  font-size: var(--basicFontSize) !important;
}

.measurement-sensor-table > th,
td > div {
  width: inherit;
  display: flex;
  justify-content: flex-start;
  align-items: center;

  white-space: nowrap;
}

.measurement-sensor-table > th,
td > label {
  width: 100%;

  white-space: nowrap;
  display: inline-block;
}

.measurement-sensor-table-header {
  background-color: transparent;
  height: 25px !important;
}
.measurement-sensor-table-header > tr > th {
  text-align: left !important;
  padding-left: 5px;
  color: var(--colorLightText);
  font-weight: bold;
}

.measurement-sensor-linkdate {
  height: 100%;
  width: 100%;
  margin-top: 2px;
  margin-bottom: 2px;

  display: flex;
  justify-content: center;
  align-items: center;
  flex-flow: column;
}

.measurement-sensor-linkdate > div {
  width: 100%;
  height: 20px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.measurement-sensor-linkdate > div::before {
  content: attr(data-starttag);
  font-weight: bold;
}

.measurement-sensor-value {
  height: 20px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 3px;
  font-weight: bold;
}

.measurement-sensor-value[data-notlinked="true"] {
  justify-content: center;
  color: var(--colorLightText);
}

.measurement-sensor-value[data-linking-ended="true"] {
  color: darkgray;
}

.measurement-sensor-depth {
  /* border: 1px solid black; */
  border-radius: 5px;
  height: 20px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 3px;
  font-weight: bold;
}

.measurement-sensor-delete-cell {
  background-color: transparent;
}

.measurement-sensor-delete-cell > button {
  height: inherit;
  padding-left: 5px;
  padding-right: 5px;
}
