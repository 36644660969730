body {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  font: 12pt "Tahoma";
  overflow-x: auto;
  overflow-y: auto;
}
* {
  box-sizing: border-box;
  -moz-box-sizing: border-box;
}
.page {
  width: 210mm;
  height: 297mm;
  padding: 10mm 10mm 20mm 10mm;
  margin: 10mm auto;
  margin-top: 5mm;
  border: 1px #d3d3d3 solid;
  border-radius: 5px;
  background: white;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
  overflow: hidden !important;
}

.page::before {
  content: var(--var-time);
  position: inherit;
  top: -10mm;
  left: 0mm;
  margin-bottom: 2mm;
  z-index: 0;
  padding: 0;
  font-size: 14px;
}

div[data-content] {
  position: relative;
  height: 100%;
  width: 100%;
  /* border: 1px solid black; */
  margin-top: 2mm;
  margin-bottom: 15mm;

  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-flow: column;
  background-color: white;
}

div[data-content] > h2 {
  margin-bottom: 5px;
}

.subpage {
  padding: 1cm;
  border: 5px red solid;
  height: 257mm;
  outline: 2cm #ffeaea solid;
}

/***************************************************************************/
.report-header {
  height: 12mm;
  width: 100%;
  display: flex;
  flex-flow: row;
  margin-top: 30px;
}
.report-header > div {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-flow: column;
}

.report-section {
  margin-top: 20px;

  width: 100%;
}

.report-disclaimer {
  font-size: 12px;
}

/***************************************************************************/
.report-floorplan-section {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-flow: column;
}

.report-floorplan-section > div[data-image] {
  height: 260mm;
  width: 100%;
  border: 2px #d3d3d3 solid;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
}

.report-floorplan-section > div[data-table] {
  height: calc(100% - 120mm);
  width: 100%;
  padding-top: 20px;
}

/***************************************************************************/

.report-history-section {
  height: 100%;
  width: 100%;
  color: black;
}
.report-history-section > div {
  padding: 2px 0px 2px 0px;
}

.report-history-section > div:first-child {
  position: relative;
  height: 50%;
  width: 100%;
}

.report-history-section > div:last-child {
  position: relative;
  height: 50%;
  width: 100%;
}

.report-history-section > div > div {
  position: relative;
  width: 100%;
  border: 1px solid black;
  background-color: white;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  padding: 10px;
}

.u-legend.u-inline {
  position: relative;
  display: flex;
  flex-flow: row;
  left: 12%;
  max-width: 100%;
  flex-direction: row;
  flex-wrap: wrap;
}

.u-legend tr:first-child {
  display: none;
}

.u-inline > tr {
  margin: auto;
  min-width: 50%;
  display: inline-flex;
}

/***************************************************************************/

.report-tools-container {
  position: fixed;
  height: 600px;
  width: 225px;
  left: 50%;
  transform: translateX(+110mm);
  border-radius: 15;
  border: 2px solid lightgray;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.1);
  background-color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-flow: column;

  transition: top 1s ease-in-out 0s;
}

.report-tools-container > div {
  box-sizing: border-box;
  margin: 0;
}

.report-tools-container > div[data-header] {
  position: relative;
  width: 100%;
  height: 30px;
  background: lightgray;

  display: flex;
  justify-content: center;
  align-items: center;
}

.report-tools-container > div[data-content] {
  position: relative;
  width: 100%;
  height: calc(100% - 30px);
  flex-grow: 1;
  background: white;

  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.report-tools-container > div[data-content] > * {
  width: 90%;
  margin-bottom: 5px;
}
.report-tools-container > div[data-content] > label {
  margin-top: 5px;
  height: 25px;
}

/***************************************************************************/

@page {
  size: A4;
  margin: 0;
}
@media print {
  html,
  body {
    width: 210mm;
    height: 297mm;

    color-adjust: exact !important;
    -webkit-print-color-adjust: exact !important;
    print-color-adjust: exact !important;
  }
  .page {
    margin: 0;
    border: initial;
    border-radius: initial;
    width: initial;
    min-height: initial;
    box-shadow: initial;
    background: initial;
    page-break-after: always;
  }

  .report-tools-container {
    display: none;
  }
}

@media only screen and (max-width: 1250px) {
  .report-tools-container {
    position: static;
    height: 600px;
    width: 100%;
    left: none;
    transform: none;
    border-radius: 15;
    border: 2px solid lightgray;
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.1);
    background-color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-flow: column;
    transition: none;
  }
}
