.tag-dialog-main-container {
  height: 700px;
  width: 450px;
  background-color: white;
  display: flex;
  align-items: center;
  flex-flow: column;
  margin-top: 20px;
  text-align: center;
}

.tag-dialog-cancel-button {
  position: relative;
  margin-bottom: 1%;
}

.tag-dialog-current-tags {
  margin: 20px;
  font-weight: bold;
}

@media only screen and (max-width: 999px) {
  
  .tag-dialog-main-container {
    width: 100%;
    padding: 5px;
  }
}