@media screen and (min-width: 1000px) {

.companies-container {
  position: relative;
  height: 100%;
  width: 100%;

  display: flex;
  justify-content: center;
  align-items: center;
  flex-flow: column;
}
}
