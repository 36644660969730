.projects-table-project-cell {
  text-align: left !important;
  white-space: normal;
  word-wrap: break-word;
  width: 40%;
}

.projects-table-company-cell {
  align-items: center;
  cursor: pointer;
}

.projects-table-address-cell {
  white-space: normal;
  word-wrap: break-word;
  width: 40%;
}

.projects-table-edit-cell {
  vertical-align: middle !important;
  width: 10%;
}

.projects-table-devices-cell {
  vertical-align: middle !important;
  border-bottom: 1px solid rgba(224, 224, 224, 1) !important;
  width: 10%;
}

.projects-table-map-cell {
  vertical-align: middle !important;
  width: 10%;
}

@media screen and (max-width: 999px) {
  .projects-table-edit-cell {
    text-align: center !important;
  }
  .projects-table-edit-cell > svg {
    font-size: 17px;
  }
}

@media screen and (max-width: 400px) {
  .projects-table-edit-cell {
    text-align: center !important;
  }
  .projects-table-edit-cell > svg {
    font-size: 17px;
  }
  .projects-table-company-cell {
    padding: 10px 0px 10px 10px !important;
  }
  .projects-table-project-cell {
    padding: 10px 0px 10px 10px !important;
  }
}
