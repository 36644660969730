.project-info-container {
  /* position: relative; */
  width: 100%;
  min-height: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  /* justify-content: center; */
  align-items: center;
  overflow: auto;
  flex-grow: 1;
}
