.companyview-info-container {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;

  overflow: auto;

  display: flex;
  flex-flow: column;
  justify-content: flex-start;
  align-items: stretch;
  padding: 5px;
}

.companyview-info-container > div > div {
  margin-bottom: 24px;
}

.company-details-buttons-container {
  display: flex;
  justify-content: flex-end;
}

.company-details-text-field-view > div > [class*="Mui-disabled"] {
  /* .disabled classes must be overridden with !important  */
  -webkit-text-fill-color: black !important;
  color: black !important;
}

.company-details-text-field-view > [class*="Mui-disabled"] {
  /* .disabled classes must be overridden with !important  */
  -webkit-text-fill-color: black !important;
  color: black !important;
  text-align: left;
}

label[class*="Mui-disabled"] {
  -webkit-text-fill-color: rgba(0, 0, 0, 0.6);
}

@media screen and (max-width: 999px) {
  .companyview-info-container {
    position: relative;
    width: 90vw;
  }
}