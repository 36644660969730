.project-details-container {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.project-details-buttons-container {
  display: flex;
  justify-content: flex-start;
}

.project-details-text-field-view > div > [class*="Mui-disabled"] {
  /* .disabled classes must be overridden with !important  */
  -webkit-text-fill-color: black !important;
  color: black !important;
}

.project-details-text-field-view > [class*="Mui-disabled"] {
  /* .disabled classes must be overridden with !important  */
  -webkit-text-fill-color: black !important;
  color: black !important;
  text-align: left;
}

label[class*="Mui-disabled"] {
  -webkit-text-fill-color: rgba(0, 0, 0, 0.6);
}
