.projectsettings-users-container {
  --controlHeight: 35px;
  position: relative;
  height: 100%;
  width: 100%;

  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-flow: column;
}

.projectsettings-controls-container {
  width: 100%;
  height: var(--controlHeight);
  margin-top: 10px;
}

.projectsettings-controls-container > * > button {
  min-width: var(--buttonWidth);
  white-space: nowrap;
}

.projectsettings-content-container {
  height: calc(100% - var(--controlHeight));
  width: 100%;
}

@media screen and (max-width: 999px) {
  .projectsettings-users-container {
    align-items: center;
    justify-content: center;
    height: 100%;
    padding: 1%;
  }
}
