.companysettings-users-container {
  --controlHeight: 35px;
  position: relative;
  height: 100%;
  width: 100%;

  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-flow: column;
}

.companysettings-controls-container {
  width: 100%;
  height: var(--controlHeight);
  margin-bottom: 0.5rem;
  padding: 5px;
}

.companysettings-controls-container > * > button {
  min-width: var(--buttonWidth);
  white-space: nowrap;
}

.companysettings-content-container {
  height: calc(100% - var(--controlHeight));
  height: 700px;
  width: 100%;
}

@media screen and (max-width: 999px) {
  .companysettings-users-container {
    position: relative;
    height: 70vh;
    width: 95vw;
  
    display: flex;
    justify-content: center;
    align-items: center;
    flex-flow: column;
  }
  .companysettings-content-container {
    height: 100%;
  }
}