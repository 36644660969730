.u-legend { 
    font-size: var(--basicFontSize);
    padding: 0;
    margin: 0;
}
.u-legend .u-marker{ 
    font-size: var(--basicFontSize);
    border-radius: 50px;
}
.u-title {
    font-size: 0px;
    padding: 0;
    margin: 0;
}
.u-legend > tr > td { 
    font-size: var(--basicFontSize) !important;
    vertical-align: middle !important;
    margin: 0;
    padding: 0;
} 
.u-live tr{
    text-align: center;
}
.u-under {
    width: 200px;
}
.u-over {
    width: 200px;
}
.u-label {
    font-weight: normal;
    font-size: var(--basicFontSize);
}
.uplot {
    line-height: 0;
}