.planview-main-container {
  position: relative;
  height: 100%;
  width: 100%;

  background-color: transparent;

  padding: 0px;

  display: flex;
  justify-content: center;
  align-items: center;
  flex-flow: row;

  overflow: hidden;
}

.planview-controls-container {
  height: 100%;
  width: 50px;
  background-color: var(--colorBackground);

  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-flow: column;
}

.planview-draw-container {
  flex-grow: 1;
  position: relative;
  width: 100%;
  height: 100%;
  border: 2px solid transparent;
  background-color: transparent;
  overflow: hidden;

  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
}

.planview-konva {
  background-color: gray;
  border: 2px solid red;
  position: relative;
  height: 99%;
  width: 99%;
  overflow: hidden;

  display: flex;
  justify-content: center;
  align-items: center;
}

.planview-control-buttons {
  transition: all 1s;
}

.planview-control-buttons:hover {
  background-color: #989898;
}

.planview-static-icon {
  transition: all 0.5;
}

.planview-animated-icon {
  animation: lockanimation !important;
  animation-duration: 5s !important;
  color: inherit;
  transition: all 0.5s;
}

@keyframes lockanimation {
  0% {
    color: var(--colorOrange);
    transform: scale(1.3);
  }
  10% {
    color: inherit;
    transform: scale(1);
  }
  20% {
    color: var(--colorOrange);
    transform: scale(1.3);
  }
  30% {
    color: inherit;
    transform: scale(1);
  }
  40% {
    color: var(--colorOrange);
    transform: scale(1.3);
  }
  50% {
    color: inherit;
    transform: scale(1);
  }
  60% {
    color: var(--colorOrange);
    transform: scale(1.3);
  }
  70% {
    color: inherit;
    transform: scale(1);
  }
  80% {
    color: var(--colorOrange);
    transform: scale(1.3);
  }
  90% {
    color: inherit;
    transform: scale(1);
  }
  100% {
    color: inherit;
    transform: scale(1);
  }
}
