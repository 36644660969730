.welcome-page-Container {
 height: 100%;
}
.welcome-page-header {
  margin-top: 30px;
  margin-bottom: 20px;
  font-size: var(--largeHeaderFontSize);
  font-weight: bold;
}

.welcome-page-message {
  margin-top: 20px;
  font-size: var(--largeHeaderFontSize);
}

.infobox {
  margin-top: 40px;
  word-wrap: break-word;
  max-width: 90%;
}