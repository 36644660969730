.devices-table-header {
    width: 90%;
    display: inline-flex;
    margin-bottom: 5px;
    white-space: nowrap;
}


.devices-table {
    flex-grow: 1;
    width: 90%;
    height: 100%;
}