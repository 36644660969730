.add-project-form-container {
  width: 600px;
  padding: 30px;
  display: flex;
  flex-direction: column;
}

.add-alarm-profile-buttons-container {
  display: flex;
  justify-content: flex-end;
  width: 60%;
}

.test {
  display: flex;
  justify-content: center;
  align-items: center;
}

@media screen and (max-width: 680px) {
  .add-project-form-container {
    width: 250px;
    height: 455px;
  }
  .add-alarm-profile-buttons-container {
    margin-top: 5px;
    margin-bottom: 5px;
  }
}