.project-view-history-container-main {
  
  display: flex;
  background-color: #fff;
  justify-content: left;
}

.project-view-history-container-alt {
  display: flex;
  justify-content: left;
  margin-top: 10px;
  background-color: #fff;
}

.project-view-history-toolbar-container {
  width: 48px;
  padding: 0;
  margin: 0;
  height: 100%;
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: var(--colorBackground);
}

.project-view-history-charts-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  flex-grow: 1;
  width: 100%;
}

.project-view-history-icons {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}

.project-view-history-buttons {
  flex-grow: 1;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.project-view-history-reset-time-range {
  color: rgba(0, 0, 0, 0.87);
  /* font-weight: bold; */
  margin-right: 30px;
  cursor: pointer;
}

.fullHeight {
  height: 100%;
  flex-grow: 1;
}

::-webkit-datetime-edit { padding: 5px; border-radius: 20px;}
::-webkit-datetime-edit-fields-wrapper { background: white; }
::-webkit-datetime-edit-text { padding: 2px; }
::-webkit-datetime-edit-month-field {font-family: "Fellix-Regular"; color: black; }
::-webkit-datetime-edit-day-field { color: black; }
::-webkit-datetime-edit-year-field { color: black; }

@media screen and (max-width: 999px) {
  .project-view-history-container-alt {
    display: flex;
    justify-content: left;
    margin-top: 0px;
    background-color: #fff;
  }
}