.company-settings-container {
  position: relative;
  height: 100%;
  width: 100%;

  display: flex;
  flex-flow: row;

  background: var(--colorBackground);
}

.company-settings-navigation {
  position: relative;
  width: 200px;
  margin-right: 3px;

  display: flex;
  flex-flow: column;
  justify-content: flex-start;
  align-items: center;
  padding: 5px;
  border-right: 1px solid var(--colorDivider);
}

.company-settings-navigation > button {
  margin-top: 3px;
  margin-bottom: 3px;
  padding: 5px;
}

@media screen and (min-width: 1000px) {
  .company-settings-content {
    position: relative;
  
    flex-grow: 1;
  
    display: flex;
    flex-flow: column;
    justify-content: flex-start;
    align-items: center;
    padding: 5px;
  
  }
}

