.device-settings-container {
  display: flex;
  width: 100%;
  min-width: 100%;
  min-height: 100% !important;
  flex-direction: row;
  align-items: flex-start;
  margin-left: 20%;
  margin-top: 20px;
  margin-bottom: 30px;
}

.device-settings-container-center {
  display: flex;
  width: 100%;
  min-width: 100%;
  min-height: 90%;
  flex-direction: column;
  align-items: center;
  margin-top: 20px;
  margin-bottom: 30px;
}

.device-settings-history-container {
  display: flex;
  flex-direction: row;
  max-width: 50%;
  min-width: 50%;
  margin-left: 20px;
  margin-right: 20px;
  margin-top: 20px;
  margin-bottom: 30px;
  min-height: 600px;
  max-height: 750px;
  overflow: hidden;
  border: 1px solid lightgray;
}

.device-settings-table-header {
  display: inline-flex;
  margin-bottom: 5px;
  margin-top: 10px;
}

.device-settings-table-container {
  margin-top: 20px;
  width: 25%;
}

.device-settings-history-loading-container {
  display: flex;
  width: 100%;
  min-width: 100%;
  min-height: 90%;
  flex-direction: row;
  align-items: flex-start;
  overflow: hidden;
  margin-left: 20%;
  margin-top: 20px;
  margin-bottom: 30px;
}

@media only screen and (max-width: 2000px) and (min-width: 1000px) {
  .device-settings-table-container {
    width: 60%;
  }
  .device-settings-container {
    margin-left: 5%;
  }
}

@media only screen and (max-width: 999px) {
  .device-settings-table-container {
    width: 95%;
    height: 90%;
  }

  .device-settings-table-header {
    width: 90%;
  }

  .device-settings-container {
    margin-bottom: 5px;
    margin-top: 5px;
    height: 90%;
    overflow: visible;
  }
}