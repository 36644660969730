/*******************************/
.app-header {
  background-color: var(--colorWhite);
  position: relative;
  width: 100%;
  height: 80px;
  flex: none;
  user-select: none;

  display: flex;
  justify-content: center;
  align-items: center;
}

.app-header > div {
  position: relative;
  height: 100%;
}
.logo-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 2rem;
}

.home-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.relia-header {
  min-width: 200px;
  display: flex;
  justify-content: left;
  align-items: center;
}

.header-text-container {
  font-weight: bold;
  flex-grow: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-flow: column;
}

.header-text-project {
  padding-left: 50px;
  text-align: left;
  width: 100%;
}

.header-text-project > p {
  width: 100%;
  white-space: nowrap;
  font-size: var(--navButtonFontSize);
  color: var(--colorLightText);
}

.header-language-container {
  flex-grow: 0;
  width: 100px;

  display: flex;

  justify-content: center;
  align-items: center;
  padding: 0;
}

.header-user-login {
  text-align: right;

  min-width: 200px;

  display: flex;
  justify-content: center;
  align-items: center;
  flex-flow: column;
  font-weight: bold;
  font-size: var(--headerFontSize);
  color: var(--colorLightText);
}
.header-user-login label[username-exists="false"]{
  margin-top: 0;
}
.header-user-login label[username-exists="true"]{
  margin-top: -18px;
  margin-right: 2%;
}

@media only screen and (max-width: 1450px) and (min-width: 501px) {
  .header-text-project > p {
    font-size: 1rem;
    white-space: break-spaces;
  }
  .header-language-container > button{
    min-width: 2rem;
  }
  .header-user-login {
    min-width: 0rem;
  }
  .header-user-login label[username-exists="false"]{
    margin-top: 0px;
  }
  .header-user-login label[username-exists="true"]{
    margin-top: -13px;
    position: fixed;
    right: 0.2rem;
    top: 0.8rem;
  }
}

@media screen and (max-width: 500px) {
  .app-header {
    width: 100%;
  }
  .logo-container {
    width: 3.5rem;
    margin-left: 3%;
    margin-right: 4%;
  }
  .logo-container img {
    width: 4rem;
  }
  .header-text-project {
    padding: 0;
    text-align: left;
    width: 100%;
    margin-right: 3%;
  }
  .header-text-project > p {
    font-size: 0.65rem;
    white-space: break-spaces;
  }
  .header-language-container {
    margin-left: 1%;
    min-width: 1rem;
  }
  .home-container {
    margin-left: 1%;
    margin-right: 2%;
  }
  .header-user-login {
    text-align: center;
    min-width: 0rem;
    margin-right: 1.5%;
  }
  .header-user-login label[username-exists="false"]{
    margin-top: 0px;
  }
  .header-user-login label[username-exists="true"]{
    margin-top: -13px;
    position: fixed;
    right: 0.2rem;
    top: 0.8rem;
  }
}

