/*******************************/
.appFooter {
  position: relative;
  width: 100%;
  height: 2rem;
  font-size: var(--headerFontSize);
  display: flex;
  justify-content: center;
  align-items: center;
  user-select: none;
}
