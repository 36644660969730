@font-face {
  font-family: "Fellix-Light"; /*Can be any text*/
  src: local("Fellix-Light"), url("./fonts/Fellix-Light.ttf") format("truetype");
}

@font-face {
  font-family: "Fellix-Medium"; /*Can be any text*/
  src: local("Fellix-Medium"), url("./fonts/Fellix-Medium.ttf") format("truetype");
}

@font-face {
  font-family: "Fellix-Regular"; /*Can be any text*/
  src: local("Fellix-Regular"), url("./fonts/Fellix-Regular.ttf") format("truetype");
}

:root {
  --colorBlack: #000;
  --colorWhite: #fff;
  --colorGray: #e5e5e5;
  --colorOrange: #eb941c;
  --colorGreen: #50c878;
  --colorRed: #fe5442;

  --primaryFontColor: #222222;
  --secondaryFontColor: #fff;

  --colorBackground: #f8f8fa;
  --colorDivider: #e0e0e2;

  --colorDeselectedText: #8c95a4;
  --colorLightText: #aaaeb5;

  --contentMinWidth: 1280px;
  --contentMinHeight: 720px;
  --basicFontSize: 12px;
  --inputFontSize: 13px;
  --largeHeaderFontSize: 24px;
  --headerFontSize: 14px;
  --buttonFontSize: 13px;
  --navButtonFontSize: 16px;
  --iconSize: 20px;
  --checkBoxSize: 24px;
}

@media only screen and (max-width: 1450px) {
  :root {
    --basicFontSize: 0.8rem;
    --inputFontSize: 0.8rem;
    --largeHeaderFontSize: 1.188rem;
    --headerFontSize: 0.8rem;
    --buttonFontSize: 0.8rem;
    --navButtonFontSize: 1rem;
    --iconSize: 1.063rem;
    --checkBoxSize: 1.188rem;
  }
}

@media only screen and (max-width: 400px) {
  :root {
    --navButtonFontSize: 0.75rem;
    --basicFontSize: 0.75rem;
    --inputFontSize: 0.75rem;
    --largeHeaderFontSize: 1rem;
    --headerFontSize: 0.75rem;
    --buttonFontSize: 0.72rem;
    --navButtonFontSize: 0.8rem;
    --iconSize: 1.063rem;
    --checkBoxSize: 1.188rem;
  }
}

body {
  margin: 0;
  font-family: "Fellix-Regular" !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  min-height: 100vh;
  min-height: -moz-available;
  min-height: -webkit-fill-available;
  min-height: stretch;
  width: 100vw;
  overflow: scroll;
}

* {
  /* DEFAULT SETTING FOR ALL COMPONENTS */
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: "Fellix-Regular";
}

/* code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
} */
