.report-main-container {
  height: 100vh;
  width: 100vh;

  overflow: auto;
  background-color: lightgray;
  position: absolute;
  height: 100%;
  width: 100%;

  display: flex;
  justify-content: center;
  align-items: center;
  flex-flow: column;

  overflow: hidden;
}

.report-main-container > div {
  position: relative;
  width: 100%;

  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
  overflow: auto;
}

.report-first-page {
  background-color: lightblue;
  page-break-after: always;
}

.report-plans-page {
  flex-grow: 1;
  background-color: lightgreen;
  page-break-after: always;
}

.report-last-page {
  background-color: lightskyblue;
  page-break-after: always;
  white-space: normal;
}

::-webkit-datetime-edit { padding: 5px; border-radius: 20px;}
::-webkit-datetime-edit-fields-wrapper { background: white; }
::-webkit-datetime-edit-text { padding: 2px; }
::-webkit-datetime-edit-month-field {font-family: "Fellix-Regular"; color: black; }
::-webkit-datetime-edit-day-field { color: black; }
::-webkit-datetime-edit-year-field { color: black; }

@media print {
  html,
  body {
    margin: 0;
    padding: 0;

   /*  background: red !important;
    color: blue !important;
    background-color: lightskyblue; */
    -webkit-print-color-adjust: exact;
    print-color-adjust: exact;
  }
  button {
    display: none;
  }

  .report-main-container {
    height: 100vh;
    width: 100vw;
  }
  div {
    page-break-after: always !important;
    /* background-color: red !important; */
  }
}
