.projectView-container {
  position: relative;
  height: 100%;
  width: 100%;
  overflow: hidden;

  display: flex;
  flex-flow: column;
  align-items: stretch;
}

.tabs-container {
  grid-area: tabs;
  width: 100%;
  background-color: white;
  padding-bottom: 5px;
}

.tabs-container > * > button {
  min-width: var(--buttonWidth);
  white-space: nowrap;
}

.tabs-container > * > button:hover {
  background-color: transparent;
}

.tabs-container > * > a {
  min-width: var(--buttonWidth);
  white-space: nowrap;
}

.tabs-container > * > a > button {
  width: 100%;
  height: 100%;
  white-space: nowrap;
}

.tabs-container > * > a > button:hover {
  background-color: transparent;
}

/****************************************************************/
/* CONTENT */
.content-container {
  grid-area: content;
  position: relative;
  width: 100%;
  flex-grow: 1;
  overflow: auto;
  background-color: white;
  height: 100%;
  display: flex;
  flex-flow: row;
  justify-content: stretch;
  align-items: stretch;
  flex-wrap: nowrap;
}
