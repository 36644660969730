.flag-image {
    width: var(--iconSize);
    margin-right: 10px;
}
.language-text {
    margin: 0;
    padding: 0;
    font-weight: bold;
    font-family: "Fellix-Regular";
    color: black;
    font-size: var(--navButtonFontSize);
}