.projectsettings-container {
  position: relative;
  width: 100%;
  height: 100%;

  display: flex;
  justify-content: center;
  flex-flow: row;

  background: var(--colorBackground);
}

.projectsettings-container > div {
  position: relative;
  height: 100%;

  display: flex;
}

.projectsettings-navigation-container {
  width: 200px;
  margin-right: 3px;

  flex-flow: column;
  justify-content: flex-start;
  align-items: center;
  padding: 5px;
  border-right: 1px solid var(--colorDivider);
}

.projectsettings-navigation-container > button {
  margin-top: 3px;
  margin-bottom: 3px;
  padding: 5px;
}

.projectsettings-content-container {
  width: 100%;
  justify-content: center;
  align-items: flex-start;

  overflow: auto !important;

  padding: 5px;
}


@media screen and (max-width: 999px) {
  .projectsettings-content-container {
    width: 100%;
    height: 100%;
    padding: 2%;
  }
}