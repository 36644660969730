.projects-page-container {
  display: flex;
  width: 100%;
  min-width: 80%;
  min-height: 80%;
  flex-direction: column;
  align-items: center;
  overflow: hidden;
}

.projects-page-header {
  margin-top: 30px;
}

.projects-page-add-project-button {
  align-self: flex-end;
}

.projects-buttons-container {
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  width: 50%;
  margin-top: 30px;
}
