.plans-container {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;

  display: flex;
  flex-flow: column;
  justify-content: baseline;
  align-items: center;

  overflow: hidden;
}

.plans-stack {
  position: relative;
  height: 100%;
  width: 100%;
  overflow: auto;
}

.plans-stack > div {
  width: 100%;
}

.plans-stack-button-row {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-flow: row;
}

.plans-stack-button-row > button[data-iseditingbutton] {
  color: black;
  width: 28px;
  height: 28px;
  background-color: white;
  border-radius: 20px;
  border-bottom: 1px solid;
  border-color: lightgray;
  margin-left: 1px;
  font-size: var(--buttonFontSize);
}

.plans-stack-button-row > button[data-iseditingbutton]:hover {
  color: black;
  width: 28px;
  height: 28px;
  background-color: lightgray;
  border-radius: 20px;
  font-size: var(--buttonFontSize);
}
