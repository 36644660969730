.link-device-form-container {
  height: 900px;
  width: 600px;
  background-color: white;
  display: flex;
  justify-content: baseline;
  align-items: center;
  flex-flow: column;

  margin-top: 20px;
}

.link-device-dialog-buttons {
  position: relative;
  margin-bottom: 1%;
}

@media only screen and (max-width: 999px) {
  
  .link-device-form-container {
    width: 100%;
  }
}