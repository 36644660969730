/*******************************/
.appContent-Container {
  background-color: var(--colorBackground);
  position: relative;
  width: 100%;
  flex-grow: 1;
  overflow-x: hidden;
  padding: 0px;
  padding-top: 0px;
}
