.measurementpointlist-main-container {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  
  display: flex;
  flex-flow: column;
}

.measurementpointlist-measurementlist-container {
  position: relative;
  width: 100%;
  height: 100%;
  justify-content: flex-start;
  align-items: center;

  overflow-x: hidden;
  overflow-y: auto;

  display: flex;
  flex-flow: column;
}

/* *********************************************************************************** */
.measurementpointlist-measurement-container {
  position: relative;
  min-height: inherit;
  width: 95%;
  margin: 10px;

  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-flow: column;
  border-radius: 12px;
  background-color: white;
  padding: 5px;
  border: 1px solid var(--colorDivider);
  transition: border-color 100ms ease-in-out, background-color 500ms ease-in-out;
}

.measurementpointlist-measurement-container[data-measurement-highlighted="true"][active-devices="true"] {
  padding: 4px;
  border: 2px solid var(--colorOrange);
  border: 2px solid var(--colorOrange);
}
.measurementpointlist-measurement-container[data-measurement-highlighted="true"][active-devices="false"] {
  padding: 4px;
  border: 2px solid var(--colorDeselectedText);
  border: 2px solid var(--colorDeselectedText);
}
/* *********************************************************************************** */
.measurementpointlist-measurement-header {
  background-color: white;
  grid-area: header;
  height: 30px;

  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
  flex-flow: row;

  transition: border-color 100ms ease-in-out, background-color 500ms ease-in-out;
}

.measurementpointlist-measurement-header > button {
  border: 0;
  background: white;
  background-color: white;
  border-bottom: 1px solid var(--colorDivider);
}

.measurementpointlist-measurement-header > input {
  flex-grow: 1;
  width: 100%;
  margin-left: 10px;
}

.measurementpointlist-measurement-header > button[data-iseditingbutton] {
  width: 40px;

  margin-left: 20px;
  margin-right: 5px;
  margin-top: 5px;
  margin-bottom: 5px;
}

.measurementpointlist-measurement-header > button[data-isdeletingbutton] {
  width: 40px;

  margin-left: 5px;
  margin-right: 10px;
  margin-top: 5px;
  margin-bottom: 5px;
}

.measurementpointlist-measurement-header > label {
  text-align: left;
  padding-left: 5px;
  font-weight: bold;

  width: 100%;

  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.measurementpointlist-measurement-header > button[data-isselectingbutton] {
  height: 100%;
  width: 100%;
  font-weight: bold;
  padding-left: 10px;
  padding-right: 10px;
  font-size: var(--headerFontSize);

  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

/****************************************************************************************/
.measurementpointlist-measurement-annotation {
  background-color: transparent;
  grid-area: annotation;

  text-align: left;

  width: 100%;

  padding-right: 10px;
  padding-left: 10px;

  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;

  display: none;
}

.measurementpointlist-measurement-annotation > input {
  width: 100%;
  font-size: 14px;
}

.measurementpointlist-measurement-annotation > label {
  text-align: left;

  width: 100%;
  font-size: 14px;
}
/****************************************************************************************/
.measurementpointlist-measurement-sensors-header {
  background-color: transparent;
  grid-area: sensors_header;
  font-size: var(--headerFontSize);
  overflow: hidden;
}

.measurementpointlist-measurement-sensors {
  background-color: white;
  grid-area: sensors;
  width: 100%;
  overflow: hidden;
}
.measurementpointlist-measurement-targetalarms {
  background-color: transparent;
  grid-area: targets;
  border-top: 1px solid black;
  display: none;
}
.measurementpointlist-measurement-addsensors {
  grid-area: addsensors;
  /* padding-bottom: 5px; */
  display: flex;
  justify-content: right;
  align-items: center;
  margin-bottom: 5px;

  background-color: white;
}
/* *********************************************************************************** */
.measurementpointlist-measurements-addmeasurement {
  background-color: transparent;
  width: 100%;
  height: 45px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 10px;
  margin-top: 10px;
}

input[type=number]::-webkit-inner-spin-button, 
input[type=number]::-webkit-outer-spin-button { 
  -webkit-appearance: none; 
  margin: 0; 
}

.license-warning {
  display: flex;
  align-items: center;
  align-self: flex-start;
  margin-left: 2.5%;
  margin-top: 4px;
}

.warning-text {
  font-size: var(--headerFontSize);
  font-weight: bold;
  color: red;
}

@media screen and (max-width: 999px) {
  .measurementpointlist-main-container {
    position: relative;
  }
  .measurementpointlist-measurement-container {
    margin: 8px;
  }
}