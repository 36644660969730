.projectsettings-licences-container {
  --controlHeight: 35px;
  position: relative;
  height: 100%;
  width: 100%;
  margin-right: 1%;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-flow: column;
}

.projectsettings-controls-container {
  width: 100%;
  height: var(--controlHeight);
}

.projectsettings-controls-container > * > button {
  min-width: var(--buttonWidth);
  white-space: nowrap;
}

.projectsettings-content-container {
  width: 100%;
}
.project-licences-text-field-view [class*="Mui-disabled"] {
  /* .disabled classes must be overridden with !important  */
  -webkit-text-fill-color: black !important;
  color: black !important;
  text-align: left;
}
.project-licences-text-field-view > div > [class*="Mui-disabled"] {
  /* .disabled classes must be overridden with !important  */
  -webkit-text-fill-color: black !important;
  color: black !important;
}
label [class*="Mui-disabled"] {
  -webkit-text-fill-color: rgba(0, 0, 0, 0.6);
}

@media screen and (max-width: 999px) {
  .projectsettings-licences-container {
    --controlHeight: 35px;
    position: relative;
    height: 100%;
    width: 98%;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-flow: column;
    margin: 2%;
    padding: 2%;
  }
}