.App-Container {
  position: absolute;
  text-align: center;
  width: 100%;
  height: 100%;
  overflow: hidden;
  display: flex;
  flex-flow: column;
}

.App-Container > div {
  position: relative;
  width: 100%;
}

.appLoading-Container {
  position: absolute;
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  background: var(--colorLightText);

  font-size: 22px;
  font-weight: bold;
}
