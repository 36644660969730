.viewContent-container {
  position: relative;
  width: 100%;

  display: flex;
  flex-flow: column;
  overflow: auto;
  background: var(--colorBackground);
  border-top: 1px solid var(--colorDivider);
}

.viewContent-container[data-showList="false"] {
  grid-template-columns: 30px auto;
}

div[data-grow-animation] {
  transition: width 500ms ease-in-out, height 500ms ease-in-out, flex-grow 500ms ease-in-out,
    opacity 500ms ease-in-out;
}

/*******************************************************/
.content-container {
  width: 100%;
  height: 100%;
  grid-area: content;
  background-color: transparent;


  padding: 2px;
  overflow: auto;
  display: flex;
  flex-flow: row;
  justify-content: center;
}

.content-container > div {
}

/* **************************************************** */

.plans-container {
  position: relative;
  width: var(--viewSize);
  background-color: transparent;

  border-right: 1px solid var(--colorDivider);
  padding-right: 5px;
  padding-left: 5px;
  overflow: auto;

  display: flex;
  align-items: center;
  flex-flow: column;
}

.plans-container[data-show="false"] {
  width: var(--viewHideSize);
}

.plans-container > div {
  width: 100%;
}

.plans-container[data-show="false"]:hover {
  width: var(--viewSize);
}

/* **************************************************** */
.plans-header {
  height: var(--naviHeight);

  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding-right: 5px;
}

.plans-selection {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-flow: column;

  opacity: 1;
}
.plans-selection button {
  font-size: var(--buttonFontSize);
}

.plans-container[data-show="false"] .plans-selection {
  opacity: 0;
}

.plans-container[data-show="false"]:hover .plans-selection {
  opacity: 1;
}

.plans-rows {
  position: relative;
  height: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: nowrap;
  flex-grow: 1;
  flex-flow: column;

  opacity: 1;
}

.plans-container[data-show="false"]:hover .plans-rows {
  opacity: 1;
}

.plans-container[data-show="false"] .plans-rows {
  opacity: 0;
}

/* **************************************************** */
.measurementpoints-container {
  position: relative;

  min-width: calc(var(--measurementWidth) - 100px);
  width: var(--measurementWidth);

  flex-grow: 0;
  background-color: transparent;
  overflow: hidden;
  padding-right: 5px;
}

.measurementpoints-container[data-plan-or-history-active="false"] {
  flex-grow: 1;
}

.measurementpoints-container[data-show="false"] {
  width: 0;
  flex-grow: 0;
  min-width: 0;
  padding-right: 0;
}
.measurementpoints-container[data-show="true"] {
  width: var(--measurementWidth);
  min-width: 0;
  padding-right: 0;
}
/* **************************************************** */
.planhistory-container {
  flex-grow: 1;
  background: #fff;
  overflow: auto;
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
  width: 40%;
  border-left: 1px solid var(--colorDivider);
  border-top: 1px solid var(--colorDivider);
}
.planhistory-container[data-show="false"] {
  width: 0;
  flex-grow: 0;
}
/* **************************************************** */
.plan-container {
  flex-grow: 1;

  position: relative;
  height: 50%;
  width: 100%;

  overflow: hidden;
  border-bottom: 1px solid var(--colorDivider);
}

.plan-container[data-show="false"] {
  height: 0;
  flex-grow: 0;
  border-bottom: 0;
}
/* **************************************************** */
.history-container {
  flex-grow: 1;
  display: flex;
  flex-direction: row;

  width: 100%;
  height: 50%;

  opacity: 1;

  overflow: hidden;

  border-bottom: 1px solid var(--colorDivider);
}

.history-container[data-show="false"] {
  height: 0;
  flex-grow: 0;
  border-bottom: 0;
}
.history-container[data-transitions="true"] {
  opacity: 0;
  transition: opacity 0s;
}
.history-container-child {
  visibility: visible;
  width: 100%;
}

@media screen and (max-width: 999px) {

  .planhistory-container {
    flex-grow: 1;
    background: #fff;
    overflow: hidden;
    display: flex;
    flex-flow: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    border-left: 1px solid var(--colorDivider);
    border-top: 1px solid var(--colorDivider);
  }
  .planhistory-container[data-show="false"] {
    width: 0;
    height: 0;
    flex-grow: 0;
  }
  .planhistory-div[data-show="false"] {
    width: 0;
    height: 0;
    flex-grow: 0;
  }
  .planhistory-div[data-edit="true"][data-show="true"] {
    height: 300px;
  }
  .planhistory-container[data-edit="true"][data-show="true"] {
    height: 300px;
  }
  .plans-container {
    max-width: 34%;
    min-width: 4%;
  }
  .plan-container {
    height: 30%;
  }
  .plan-container[data-show="false"] {
    height: 0;
    flex-grow: 0;
    border-bottom: 0;
  }
  .history-container {
    height: 70%;
  }
  .history-container[data-show="false"] {
    height: 0;
    flex-grow: 0;
    border-bottom: 0;
  }
}