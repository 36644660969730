.report-table-container {
  position: relative;
  height: 100%;
  width: 100%;

  display: flex;
  justify-content: center;
  align-items: center;
  flex-flow: column;
}

.report-table-measurement {
  position: relative;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-flow: column;

  margin-bottom: 10px;
}

.report-table-row-header {
  width: 100%;

  padding-left: 5px;
  padding-bottom: 2px;

  background-color: lightgray !important;
}

.report-table-row {
  width: 100%;
  font-size: 12px;
  height: 62px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-flow: row;
}

.report-table-row[data-isheader] {
  background-color: lightgray !important;
  height: 50px;
  margin-bottom: 10px;
}

.report-table-cell {
  border: 1px solid black;
  height: 100%;
  padding-top: 2px;
  padding-bottom: 2px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-flow: column;
  text-align: center;
}

.report-table-cell:nth-child(1) {
  width: 13%;
}
.report-table-cell:nth-child(2) {
  width: 13%;
}
.report-table-cell:nth-child(3) {
  width: 14%;
}
.report-table-cell:nth-child(4) {
  width: 20%;
}
.report-table-cell:nth-child(5) {
  width: 15%;
}
.report-table-cell:nth-child(6) {
  width: 20%;
}
.report-table-cell:nth-child(7) {
  width: 20%;
}

@media print {
  .report-table-row[data-isheader] {
    background-color: lightgray !important;
  }
  .report-table-row-header {
    background-color: lightgray !important;
  }
}
