.project-alarms-container {
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  overflow: auto;
}

.project-alarms-container-view {
  width: 100%;
  height: calc(100% - 60px);
  display: flex;
  flex-direction: column;
}

.project-alarms-alarmprofiles {
  width: 20%;
  height: 100vh - 400px;
  border-radius: 12px;
}

.project-alarms-content {
  height: 100%;
  display: flex;
}

.project-alarms-alarmprofiles-selectedrow {
  background-color: #505050;
}

.project-alarms-alarmprofiles-selectedrow > th {
  font-weight: bold !important;
}

.project-alarms-devices {
 width: 100%;
 min-height: 175px;
}

.project-alarms-selectedprofile {
  display: flex;
  background-color: var(--colorWhite);
  height: 60px;
  padding: 12px;
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  font-size: var(--headerFontSize);
}

.project-alarms-alarmprofiles {
  margin-right: 10px;
  height: 100%;
  min-width: 100px;
  overflow-x: hidden;
}

.project-alarms-edit-button {
  width: 160px;
}

.project-alarms-selectedprofile-text {
  margin-left: 10px;
}

.project-alarms-alarmprofiles-row {
  cursor: pointer;
}

@media only screen and (max-width: 1450px) {
  .project-alarms-container-view {
    width: 100%;
    height: calc(100% - 50px);
    display: flex;
    flex-direction: column;
  }
  .project-alarms-selectedprofile {
    height: 50px;
    padding: 9px;
  }
}

@media only screen and (max-width: 999px) {
  .project-alarms-alarmprofiles {
    width: 100%;
    height: 100%;
    border-radius: 12px;
  }
}