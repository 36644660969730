.add-device-form-container {
  height: 400px;
  width: 550px;
  background-color: white;

  display: flex;
  justify-content: baseline;
  align-items: center;
  flex-flow: column;

  margin-top: 20px;
}

.add-device-form-container > div {
  width: 90%;
  margin-bottom: 20px;
  margin-left: 20px;
  margin-right: 20px;
}

.add-device-form-container > button {
  width: 90%;
  margin-bottom: 5px;
  margin-left: 20px;
  margin-right: 20px;
}

.add-device-form-header {
  font-size: var(--headerFontSize);
  font-weight: bold;
}

@media screen and (max-width: 680px) {
  .add-device-form-container {
    width: 250px;
    height: 450px;
    padding: 15px;
  }
}