.device-management-container {
    display: flex;
    width: 100%;
    min-width: 80%;
    min-height: 80%;
    flex-direction: column;
    align-items: center;
    margin-bottom: 20px !important;
  }

  .device-management-header {
    margin-top: 20px;
    margin-bottom: 30px;
  }
