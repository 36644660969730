.linkdevice-form-container {
  height: 800px;
  width: 600px;
  background-color: white;
  display: flex;
  justify-content: baseline;
  align-items: center;
  flex-flow: column;
  margin-top: 10px;
}

.linkdevice-form-container > div {
  width: 90%;
  margin-bottom: 10px;
  margin-left: 20px;
  margin-right: 20px;
}

.lindevice-tabs-content-container {
  flex-grow: 0;
  display: flex;
  justify-content: baseline;
  align-items: center;
  flex-flow: column;
}

.lindevice-tabs-content-container > div {
  position: relative;
  height: 100%;
  width: 100%;
  padding-top: 10px;
  padding-bottom: 10px;
  display: flex;
  justify-content: baseline;
  align-items: center;
  flex-flow: column;
}

.lindevice-tabs-content-container > div > div {
  width: 100%;
}

.linkdevice-form-container > button {
  width: 90%;
  margin-bottom: 5px;
  margin-left: 20px;
  margin-right: 20px;
}

.linkdevice-form-header {
  font-size: var(--headerFontSize);
  font-weight: bold;
}

@media screen and (max-width: 680px) {
  .linkdevice-form-container {
    width: 95%;
    height: 600px;
    padding: 0px;
    align-self: center;
    margin: 2px;
    overflow: hidden !important;
  }
@media screen and (max-height: 600px) {
  .linkdevice-form-container {
    width: 95%;
    height: 600px;
    padding: 0px;
    align-self: center;
    margin: 2px;
    overflow: hidden !important;
  }
  }
}