:root {
  --viewSize: 200px;
  --viewHideSize: 40px;
  --naviHeight: 45px;
  --boxCornerRadius: 7px;
  --measurementWidth: 400px;
  --buttonWidth: 150px;
}

@media only screen and (max-width: 1450px) {
  :root {
    --measurementWidth: 340px;
    --buttonWidth: 100px;
  }
}

@media only screen and (max-width: 450px) {
  :root {
    --measurementWidth: 340px;
    --buttonWidth: 76px;
  }
}